import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import LastImg from "../../assets/images/case-study/last2.png";

const query = graphql`
  {
    file(relativePath: { eq: "images/case-study/placeholder.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AirUp = () => {
  const data = useStaticQuery(query);

  return (
    <section className="m-airUp">
      <div className="_wr">
        <div className="_w m-airUp__topContent">
          <div className="_l8">
            <h2>AIR UP LIVE!</h2>
            <p className="a-text -red">Solution</p>
            <p className="m-airUp__topContent--text">
              We delivered a full end to end mobile first design of the
              e-comerce website covering all flows and specs, which could be
              seamlessly translated into a final product.
            </p>
          </div>
        </div>
        <Image fluid={data.file.childImageSharp.fluid} />
      </div>
    </section>
  );
};

export default AirUp;
