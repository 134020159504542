import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import Develop from "../components/global/develop";
import { useStaticQuery, graphql } from "gatsby";
import HeaderGlobal from "../components/headers/header-global";
import Design from "../components/case-study/design";
import AirUp from "../components/case-study/air-up";

// Header Content
const hTitle = "Case Study";
const hTextOne =
  "    Lorem ipsum dolor sit amet consectetur adipisicing elit. In, vel";

const query = graphql`
  {
    file(relativePath: { eq: "images/headers/app-dev.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const CaseStudy = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <Seo title="Application Development" />
      <HeaderGlobal hTitle={hTitle} hTextOne={hTextOne} image={data.file.childImageSharp.fluid} />
      <Design />
      <AirUp />
      <Develop />
    </Layout>
  );
};

export default CaseStudy;