import React from "react";

const Design = () => {
  return (  <section className="m-design">
  <div className="_wr">
    <div className="_w m-design__top">
      <div className="_l9">
        <h2 className="m-design__top--title">
          Designing branded shopping expirience with best practice mobile
          e-commerce UX
        </h2>
        <span className="a-line -red"></span>
        <a className="a-btn -redBorder a-hover -effectThree" href="/#">
          Visit website
          <span className="a-arrow -long"></span>
        </a>
      </div>
    </div>

    <div className="_w">
      <div className="_m3 _6 m-design__itemContent">
        <div className="m-design__item">
          <h3 className="m-design__item--opacity">industry</h3>
          <h3 className="m-design__item--title">fooodtech</h3>
        </div>
      </div>
      <div className="_m3 _6 m-design__itemContent">
        <div className="m-design__item">
          <h3 className="m-design__item--opacity">went live</h3>
          <h3 className="m-design__item--title">August 2020</h3>
        </div>
      </div>
      <div className="_m3 _6 m-design__itemContent">
        <div className="m-design__item">
          <h3 className="m-design__item--opacity">stage</h3>
          <h3 className="m-design__item--title">scale up</h3>
        </div>
      </div>
      <div className="_m3 _6 m-design__itemContent">
        <div className="m-design__item">
          <h3 className="m-design__item--opacity">services</h3>
          <h3 className="m-design__item--title">User Interface</h3>
          <h3 className="m-design__item--title -last">User expiriance</h3>
        </div>
      </div>
    </div>
  </div>
</section>

  );
};

export default Design;